<template>
<div class="c-news-carousel">
  <transition mode="out-in" @enter="enter" @leave="leave">
    <article :key="index" class="news" ref="news">
      <time
        class="news__date"
        :datetime="news[index].data.mr"
        :data-animation="firstEnter ? 'paragraph' : firstEnter"
        :data-id="firstEnter ? 'scrollAnimations' : firstEnter"
      >
        {{ news[index].data.content }}
      </time>
      <h4
        class="news__title"
        :data-animation="firstEnter ? 'titleSplit' : firstEnter"
        :data-id="firstEnter ? 'scrollAnimations' : firstEnter"
        v-html="news[index].title"
      />

      <div class="news__content">
        <p
          class="news__editor"
          :data-animation="firstEnter ? 'paragraph' : firstEnter"
          :data-id="firstEnter ? 'scrollAnimations' : firstEnter"
        >
          by
          <strong v-html="news[index].editor" />
        </p>

        <p
          class="news__text"
          :data-animation="firstEnter ? 'paragraph' : firstEnter"
          :data-id="firstEnter ? 'scrollAnimations' : firstEnter"
        >{{ news[index].content }}</p>

        <a
          class="news__button"
          :href="news[index].link.href"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Link to article"
        >
          <AppButton
            :content="news[index].link.content"
            :isAnimated="firstEnter"
            aria-label="Discover news button"
          />
        </a>
      </div>
    </article>
  </transition>
</div>
</template>

<script>
import { gsap } from 'gsap';
import AppButton from '@/components/AppButton.vue';
import { init, enter } from '@/scripts/animations';
import news from '@/assets/data/news.json';

export default {
  name: 'NewsCarousel',

  components: {
    AppButton,
  },

  data() {
    return {
      index: 0,
      firstEnter: true,
      news,
    };
  },

  mounted() {
    this.resize();
    this.$eventHub.$on('windowResize', this.resize);
  },

  methods: {
    updateIndex(val) {
      this.firstEnter = false;

      this.index = val > 0 ? (this.index + 1) % this.news.length : this.index === 0 ? this.news.length - 1 : this.index - 1; // eslint-disable-line
    },

    enter(el, done) {
      gsap.to(this.$el, { height: `${el.getBoundingClientRect().height}px`, duration: 1 });

      const date = el.querySelector('.news__date');
      const title = el.querySelector('.news__title');
      const editor = el.querySelector('.news__editor');
      const content = el.querySelector('.news__text');
      const button = el.querySelector('.news__button button');
      const tl = gsap.timeline({ onComplete: done });

      init.get('paragraph')(date);
      init.get('titleSplit')(title);
      init.get('paragraph')(editor);
      init.get('paragraph')(content);
      init.get('button')(button);

      tl.addLabel('start')
        .add(enter.get('paragraph')(date), 'start')
        .add(enter.get('titleSplit')(title), 'start+=0.15')
        .add(enter.get('paragraph')(editor), 'start+=0.4')
        .add(enter.get('paragraph')(content), 'start+=0.6')
        .add(enter.get('button')(button), 'start+=0.8');
    },

    leave(el, done) {
      gsap.to(el, {
        y: -70,
        autoAlpha: 0,
        onComplete: done,
      });
    },

    resize() {
      gsap.set(this.$el, { height: `${this.$refs.news.getBoundingClientRect().height}px` });
    },
  },

  beforeDestroy() {
    this.$eventHub.$off('windowResize', this.resize);
  },
};
</script>

<style lang="scss" scoped>
.news {
  position: relative;

  @extend %grid-column;

  &__date {
    display: block;
    position: relative;
    margin-bottom: 6.5vh;
    grid-column: 1 / -1;
  }

  &__title {
    grid-column: 1 / 4;
  }
  &__content {
    grid-column: 1 / -1;

    p small {
      line-height: 1;
      text-decoration: none;
    }
  }

  &__title {
    @extend %font-news-title;
  }

  &__button {
    display: block;
    position: relative;
    margin-top: 9vh;

    font-size: 14px;

    @include mq(d) {
      font-size: 14px;
      font-size: rem(14px);
      font-size: min(calc(14px * var(--rw)), 18px);
    }

    button {
      padding: 16px 26px;
      border-radius: 60px;
    }
  }

  @include mq(ipadP) {
    &__date {
      grid-column: 3 / 14;
    }

    &__title {
      grid-column: 3 / 9;
    }

    &__content {
      grid-column: 10 / 16;
    }
  }

  @include mq(ipadL) {
    &__content {
      grid-column: 10 / 14;
    }
  }
}
</style>
