<template>
  <main class="about" data-color="#E0D8D3">
    <section class="c-page-section hero">
      <div class="hero__title">
        <h1 ref="title">
          <span>
            <span class="u-split-container">
              <span class="split-line">
                By hiring
              </span>
            </span>
          </span>
          <span>
            <span class="u-split-container">
              <span class="split-line">
                someone with
              </span>
            </span>
          </span>
          <span>
            <span class="u-split-container">
              <span class="split-line">
                Down syndrome
              </span>
            </span>
          </span>
        </h1>
      </div>

      <p class="hero__content hero__content--big" ref="introText">
        you start a virtuous chain. The more that people with Down syndrome are seen at work, the more they’ll be recognized as valuable employees, and the more they’ll be hired.
      </p>

      <div class="hero__arrow">
        <ScrollArrow direction="down" ref="circularButton"/>
      </div>

      <h2 class="hero__content" ref="introTextSmall">
        Do your part, start the<br><b>#HiringChain</b> today.
      </h2>
    </section>

    <section class="c-page-section intro">
      <SectionTitle class="intro__title" content="What"/>

      <div class="intro__content">
        <p data-animation="paragraph" data-id="scrollAnimations">
          <!-- <small>introduction</small> -->
          Sting, the award-winning artist, lends his voice to the global awareness campaign “The Hiring Chain” that calls on employers and workplaces to be more inclusive. The punchy, joyful lyrics come to life on screen and bear witness to the “virtuous” chain of inclusion in the workplace.
        </p>
        <p data-animation="paragraph" data-id="scrollAnimations">
          CoorDown takes up the challenge of the pandemic and the social crisis to affirm that job inclusion is not only a right to be guaranteed now more than ever for every person, but it brings benefits in the workplace and in society at large.
        </p>
      </div>
    </section>

    <section class="c-page-section cards">
      <CardsSlider />
    </section>

    <section class="c-page-section news">
      <SectionTitle class="news__title" content="Why"/>

      <NewsCarousel class="news__carousel" ref="carousel" />

      <div class="news__buttons">
        <!-- <button class="news__button news__button--prev" @click="$refs.carousel.updateIndex(-1)">
          <span>
            Prev Article
          </span>
          <img src="/assets/svg/arrow-left.svg" alt="Prev article arrow">
        </button> -->
        <button
          class="news__button news__button--next"
          @click="$refs.carousel.updateIndex(+1)"
          data-animation="carouselArrow"
          data-id="scrollAnimations"
          aria-label="Button to next article"
        >
          <span class="u-split-container">
            Next Article
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 30.2 32.2">
            <rect y="14.7" width="27.5" height="2.8"/>
            <polygon points="12.4,1.6 14,0 30.2,16.1 14,32.2 12.4,30.6 27,16.1"/>
          </svg>
        </button>
      </div>
    </section>

    <section class="c-page-section sponsor">
      <SectionTitle class="news__title" content="With"/>

      <div class="sponsor__item sponsor__item--double">
        <LogoGrid theme="thin" :content="{ title: 'In partnership', subtitle: 'with', logos: logos.partnership }" />
        <LogoGrid theme="thin" :content="{ title: 'Under the', subtitle: 'patronage of', logos: logos.patronage }" />
      </div>
      <div class="sponsor__item sponsor__item--single">
        <LogoGrid theme="wide" :content="{ title: 'With the', subtitle: 'collaboration of', logos: logos.collaboration }" />
      </div>
    </section>

    <footer class="c-page-section footer" data-animation="footer" data-id="scrollAnimations">
      <span class="footer__border"/>

      <div class="footer__item footer__item--left">
        <p class="item__heading">POLICY</p>

        <div class="item__content">
          <a href="https://www.iubenda.com/privacy-policy/19035635" target="_blank" rel="noopener noreferrer">
            <span>Privacy</span>
            <br>
            <span>Terms</span>
          </a>
          <a href="https://www.iubenda.com/privacy-policy/19035635/cookie-policy" target="_blank" rel="noopener noreferrer">
            <span>Cookie</span>
          </a>
        </div>
      </div>

      <div class="footer__item footer__item--center">
        <p class="item__heading">©2021. Coor Down</p>
        <p class="item__content">Campaign For<br>World Down<br>syndrome Day</p>
      </div>

      <div class="footer__item footer__item--right">
        <p class="item__heading">TOP</p>
        <div class="footer__arrow">
          <ScrollArrow direction="up"/>
        </div>
      </div>

      <SocialList class="footer__list" />
    </footer>
  </main>
</template>

<script>
import { gsap } from 'gsap';
import ScrollArrow from '@/components/ScrollArrow.vue';
import SectionTitle from '@/components/SectionTitle.vue';
import CardsSlider from '@/components/CardsSlider.vue';
import NewsCarousel from '@/components/NewsCarousel.vue';
import LogoGrid from '@/components/LogoGrid.vue';
import SocialList from '@/components/SocialList.vue';
import { init, enter } from '@/scripts/animations';
import medusa from '@/scripts/medusa';

import logos from '@/assets/data/logos.json';

export default {
  name: 'About',

  components: {
    ScrollArrow,
    SectionTitle,
    CardsSlider,
    NewsCarousel,
    LogoGrid,
    SocialList,
  },

  data() {
    return {
      logos,
    };
  },

  methods: {
    enter() {
      init.get('bigTitle')(this.$refs.title);
      init.get('paragraph')(this.$refs.introText);

      const tl = gsap.timeline();
      tl.addLabel('start')
        .add(enter.get('bigTitle')(this.$refs.title), 'start')
        .add(enter.get('paragraph')(this.$refs.introText), 'start+=0.5');

      if (this.$refs.circularButton.$el.getBoundingClientRect().top + 50 > window.innerHeight) {
        this.$refs.circularButton.$el.setAttribute('data-animation', 'circularButton');

        medusa.addNode(this.$refs.circularButton.$el, 'scrollAnimations');
      } else {
        init.get('circularButton')(this.$refs.circularButton.$el);

        tl.add(enter.get('circularButton')(this.$refs.circularButton.$el), 'start+=0.75');
      }

      if (this.$refs.introTextSmall.getBoundingClientRect().top + 50 > window.innerHeight) {
        this.$refs.introTextSmall.setAttribute('data-animation', 'paragraph');

        medusa.addNode(this.$refs.introTextSmall, 'scrollAnimations');
      } else {
        init.get('paragraph')(this.$refs.introTextSmall);

        tl.add(enter.get('paragraph')(this.$refs.introTextSmall), 'start+=1');
      }

      return tl;
    },
  },
};
</script>

<style lang="scss" scoped>
.about {
  padding: 22vh 6.4vw 9.85vh;
  width: 100%;
  overflow: hidden;

  .c-section-title {
    grid-column: 1 / -1;
    align-self: start;
    justify-self: start;
    margin-bottom: 9.85vh;
  }

  @include mq(ipadP) {
    padding: 18.5vh 4vw;

    .c-section-title {
      grid-column: 3 / 8;
      margin-bottom: 16.75vh;
    }
  }

  .hero {
    &__title {
      grid-column: 1 / -1;
      align-self: start;
      justify-self: start;

      h1 {
        display: block;
        text-align: center;

        @extend %font-page-title;
      }

      span {
        display: block;

        &:nth-child(2) {
          transform: translateY(-15%);
        }
        &:nth-child(3) {
          transform: translateY(-30%);
        }
      }
    }

    &__content {
      grid-column: 1 / -1;
      text-align: center;
      font-weight: normal;
      line-height: 1.2;

      &--big {
        @extend %font-intro;
      }
    }

    &__arrow {
      position: relative;
      grid-column: 1 / -1;
      text-align: center;
      margin: 7.38vh 0;
    }

    @include mq(ipadP) {
      &__title {
        grid-column: 1 / -1;
        justify-self: center;

        h1 {
          transform: none;
        }
      }

      &__content {
        grid-column: 3 / -3;
      }

      &__arrow {
        margin: 5vh 0;
      }
    }
  }

  .intro {
    &__content {
      grid-column: 1 / -1;

      @include mq(ipadP) {
        grid-column: 8 / -2;
      }

      @include mq(ds) {
        grid-column: 8 / 13;
      }
    }
  }

  .news {
    &__carousel {
      grid-column: 1 / -1;
    }

    &__buttons {
      grid-column: 1 / -1;
      margin-top: 6vh;
    }

    &__button {
      position: relative;

      @include fluid-type(1440px, 3840px, 36px, 96px, 72px, 192px);

      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        display: inline-block;

        @include hover-underline(12%, 2px, $black);
      }

      svg {
        display: block;
        position: absolute;
        top: 30%;
        left: calc(100% + 15px);
        height: 50%;
        width: auto;

        overflow: visible;
      }
    }

    @include mq(ipadP) {
      &__buttons {
        grid-column: 10 / 14;
        margin-top: 10vh;
      }

      &__button {
        font-size: 30px;
      }
    }

    @include mq(ipadL) {
      &__button {
        font-size: 36px;
      }
    }

    @include mq($and: $is-not-touch-device) {
      &__button {
        span,
        svg {
          transition: transform 0.5s $ease-out;
        }
        &.news__button--prev:hover {
          span {
            transform: translateX(-25px);
            transition-delay: 0.025s;
          }
          svg {
            transform: translateX(-15px);
          }
        }
        &.news__button--next:hover {
          span {
            transform: translateX(15px);
          }
          svg {
            transform: translateX(25px);
            transition-delay: 0.025s;
          }
        }
      }
    }
  }

  .sponsor {
    &__item {
      grid-column: 1 / -1;

      @include mq($until: ipadP) {
        &--double {
          * {
            margin-bottom: 4.9vh;
          }
        }
      }
    }

    @include mq(ipadP) {
      &__item {
        grid-column: 3 / 15;

        &--double {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          * {
            flex: 0 0 calc(50% - 2.1vw);
          }
        }

        & + .sponsor__item {
          margin-top: 13.40vh;
        }
      }
    }
  }

  .footer {
    padding: 5.5vh 0 0;

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;

      background-color: $black;
    }

    &__item {
      a {
        display: block;
        line-height: 1.2;

        span {
          display: inline-block;
          position: relative;
          width: min-content;

          @include hover-underline(-2px, 2px, $black);
        }

        @include mq($and: $is-not-touch-device) {
          &:hover span::before {
            transform: scaleX(1);
          }
        }
      }
      p {
        margin-top: 0;
        line-height: 1.2;
      }

      &--left {
        grid-row: 2;
        grid-column: 1 / 2;
      }
      &--center {
        grid-row: 2;
        grid-column: 3 / -1;
      }
      &--right {
        display: none;
      }

      .item__heading {
        font-size: 15px;
        margin-bottom: 7.4vh;
      }
    }

    &__list {
      grid-row: 1;
      grid-column: 1 / -1;
      margin: 0 0 5vh;
    }

    @include mq(ipadP) {
      padding: 9.5vh 0 0;

      &__border {
        left: 6%;
        width: 88%;
      }

      &__item {
        &--left {
          grid-row: 1;
          grid-column: 3 / 6;
        }
        &--center {
          grid-row: 1;
          grid-column: 7 / 11;
          text-align: center;
        }
        &--right {
          display: block;
          grid-row: 1;
          grid-column: 14 / 16;
          text-align: center;
        }

        .item__heading {
          font-size: 15px;
          margin-bottom: 6.7vh;
        }
      }

      &__list {
        grid-row: 2;
        grid-column: 8 / 10;
        margin: 7.6vh 0 0;
      }
    }
  }
}
</style>
