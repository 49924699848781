<template>
<svg
  class="scroll-arrow"
  xmlns="http://www.w3.org/2000/svg"
  x="0px" y="0px"
  viewBox="0 0 86 86"
  :data-type="direction"
  @click="onClick"
>
  <circle cx="43" cy="43" r="41.7"/>
  <rect x="41.6" y="29.3" width="2.8" height="27.5"/>
  <polygon points="57.5,41.5 59.1,43.1 43,59.2 26.9,43.1 28.5,41.5 43,56" v-if="direction === 'down'"/>
  <polygon points="28.5,44.4 26.9,42.8 43,26.6 59.1,42.8 57.5,44.4 43,29.8" v-else/>
</svg>
</template>

<script>
import { gsap } from 'gsap';

export default {
  name: 'ScrollArrow',

  props: {
    direction: {
      type: String,
      required: true,
    },
  },

  methods: {
    onClick() {
      const scroll = { x: this.$root.$el.scrollTop };

      gsap.to(scroll, {
        x: this.direction === 'down' ? `+=${window.innerHeight}` : 0,
        duration: this.direction === 'down' ? 0.8 : 1.6,
        onUpdate: () => {
          this.$root.$el.scrollTop = scroll.x;
        },
        ease: 'coor',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-arrow {
  width: 20vw;

  cursor: pointer;

  circle {
    fill: none;
    stroke: $black;
    stroke-width: 1px;
  }

  @include mq(ipadP) {
    width: 5.97vw;
  }
}
</style>
