<template>
<div class="c-cards-slider cards-slider" data-animation="slider" data-id="scrollAnimations" @mouseenter="mouseenter" @mouseleave="mouseleave">
  <img class="cards-slider__bg-lines cards-slider__bg-lines--dsk" src="/assets/svg/line.svg" alt="Connection line between cards">
  <img class="cards-slider__bg-lines cards-slider__bg-lines--mbl" src="/assets/svg/line-mobile.svg" alt="Connection line between cards">

  <ul class="cards-slider__container" ref="container">
    <li
      v-for="(card, i) in cardsData"
      class="cards-slider__card card"
      :key="`card-${i}`"
    >
      <div class="card__title">
        <p>{{ card.role }}</p>

        <h4 class="u-split-container">
          <span class="split-line" v-html="card.title" />
        </h4>
      </div>

      <div class="card__container">
        <figure class="card__image-cont">
          <img class="card__image" :src="card.img.src" :alt="card.img.alt">
        </figure>

        <div class="card__content">
          <q>{{ card.quote.content }}</q>
          <p v-html="card.quote.sign" />
        </div>
      </div>
    </li>
  </ul>

  <div class="cards-slider__drag" ref="cursor">
    <span />
    <p>
      Drag
    </p>
  </div>
</div>
</template>

<script>
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { isTouchDevice } from '@/scripts/utils';
import cardsData from '@/assets/data/cards.json';

export default {
  name: 'CardsSlider',

  data() {
    return {
      isTouchDevice: isTouchDevice(),
      cardsData,
      cards: [],
      animation: 0,
      handlerMousemove: null,
      mouseCoord: { x: 0, y: 0 },
      cursor: {
        width: 0,
        height: 0,
      },
    };
  },

  mounted() {
    gsap.to(this.$refs.cursor, { scale: 0, ease: 'coor', duration: 0.5 });

    setTimeout(() => {
      this.setUnits();
      gsap.set(this.$el, { height: this.boxBounding.height });
    }, 1000);

    this.handlerSlider = () => this.init();
    this.$eventHub.$on('initSlider', this.handlerSlider);

    if (!this.isTouchDevice) {
      this.handlerMousemove = (e) => this.mousemove(e);
    }
  },

  methods: {
    init() {
      this.$eventHub.$off('initSlider', this.handlerSlider);
      const proxy = document.createElement('div');

      this.resize();

      this.drag = Draggable.create(proxy, {
        type: 'x',
        trigger: this.$el,
        inertia: true,
        throwResistance: 5000,
        maxDuration: 0.8,
        minDuration: 0.4,
        onDrag: () => this.updateProgress(),
        onThrowUpdate: () => this.updateProgress(),
      });

      if (!this.isTouchDevice) {
        gsap.to(this.$refs.cursor, {
          scale: 1,
          ease: 'coor',
          duration: 0.5,
          delay: 0.2,
        });
      }

      this.$eventHub.$on('windowResize', this.resize);
    },

    setUnits() {
      this.cardsEls = [...this.$el.querySelectorAll('.card')];
      this.boxBounding = this.cardsEls[0].getBoundingClientRect();
      this.boundingCard = this.boxBounding.width + (window.innerWidth * (window.innerWidth < 768 ? 0.055 : 0.11));
      this.containerWidth = (this.cardsEls.length) * this.boundingCard;
      this.wrapVal = gsap.utils.wrap(0, this.containerWidth);
    },

    resize() {
      this.setUnits();

      gsap.set(this.$el, { height: this.boxBounding.height });
      gsap.set(this.$refs.container, { left: -1 * (this.boxBounding.width + this.$el.getBoundingClientRect().left + 5) });
      gsap.set(this.cardsEls, { x: (index) => index * this.boundingCard });

      if (!this.isTouchDevice) {
        this.cursor.width = this.$refs.cursor.getBoundingClientRect().width;
        this.cursor.height = this.$refs.cursor.getBoundingClientRect().height;
      }

      this.animation = gsap.to(this.cardsEls, {
        duration: 2,
        x: `+=${this.containerWidth}`,
        ease: this.isTouchDevice ? 'linear' : 'power1.inOut',
        paused: true,
        modifiers: {
          x: (x, target) => {
            x = parseInt(x) % this.containerWidth; // eslint-disable-line
            target.style.visibility = x - this.boxBounding.width > window.innerWidth ? 'hidden' : 'visible'; // eslint-disable-line

            return `${x}px`;
          },
        },
      });

      this.animation.render(this.animation.time(), false, true);
    },

    mousemove(e) {
      this.mouseCoord = { x: e.clientX, y: e.clientY };

      if (this.drag) {
        gsap.to(this.$refs.cursor, {
          x: this.mouseCoord.x - (this.cursor.width / 2),
          y: this.mouseCoord.y - (this.cursor.height / 2),
          ease: 'coor',
          duration: 1,
        });
      }
    },

    mouseenter() {
      if (this.isTouchDevice) return;

      window.addEventListener('mousemove', this.handlerMousemove);
      if (this.drag) gsap.to(this.$refs.cursor, { scale: 1, ease: 'coor', duration: 0.5 });
    },

    mouseleave() {
      if (this.isTouchDevice) return;

      window.removeEventListener('mousemove', this.handlerMousemove);
      this.handlerMouaseMove = null;
      gsap.to(this.$refs.cursor, { scale: 0, ease: 'coor', duration: 0.5 });
    },

    updateProgress() {
      this.animation.progress(this.wrapVal(this.drag[0].x) / this.containerWidth);
      gsap.set(this.cardsEls, { y: (i, el) => `${Math.sin((Math.PI / (window.innerWidth / (window.innerWidth < 768 ? 1.2 : 2))) * el.getBoundingClientRect().left) * 50}px` });
    },
  },

  beforeDestroy() {
    this.$eventHub.$off('initSlider', this.handlerSlider);
    this.$eventHub.$off('windowResize', this.resize);
    if (this.handlerMouaseMove) window.removeEventListener('mousemove', this.handlerMousemove);
  },
};
</script>

<style lang="scss" scoped>
.cards-slider {
  position: relative;
  grid-column: 1 / -1;

  &__container {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &__bg-lines {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 100vw;
    height: auto;
    max-width: unset;

    transform: translate(-50%, -50%);
    pointer-events: none;

    @include mq(ipadP) {
      &--mbl {
        display: none;
      }
    }
    @include mq($until: ipadP) {
      &--dsk {
        display: none;
      }
    }
  }

  &__drag {
    position: fixed;
    top: 0;
    left: 0;
    width: 6vw;
    min-width: 65px;
    height: 6vw;
    min-height: 65px;

    color: $white;

    display: flex;
    align-items: center;
    justify-content: center;

    pointer-events: none;

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-color: $black;
      border-radius: 50%;
    }

    p {
      position: relative;
      transform: rotate(-20deg);
      font-size: 18px;
      text-transform: capitalize;
      font-family: 'Tropiline';

      @include mq(ipadP) {
        font-size: rem(20px);
        font-size: calc(20px * var(--rw));
      }
    }
  }

  .card {
    position: absolute;
    top: 0;
    width: 75vw;

    text-align: center;

    @include mq(mobileL) {
      width: 55.5vw;
    }

    @include mq(ipadL) {
      width: 27.5vw;
    }

    p {
      margin-bottom: 20px;

      font-size: 14px;

      @include mq(ds) {
        font-size: rem(16px);
        font-size: calc(16px * var(--rw));
      }
    }

    h4 {
      font-family: 'Tropiline';
      font-size: rem(35px);
      font-size: calc(35px * var(--rw));
      line-height: rem(37px);
      line-height: calc(37px * var(--rw));
      letter-spacing: -0.1rem;

      @include mq(ipadP) {
        font-size: rem(80px);
        font-size: calc(80px * var(--rw));
        line-height: rem(82px);
        line-height: calc(82px * var(--rw));
      }

      @include mq(ipadL) {
        font-size: rem(65px);
        font-size: calc(65px * var(--rw));
        line-height: rem(65px);
        line-height: calc(65px * var(--rw));
      }
    }

    &__title {
      position: relative;
      margin-bottom: -7%;
      width: fit-content;
      transform: translateX(-50%);
      left: 50%;

      z-index: 5;

      @include mq(ipadP) {
        margin-bottom: -7%;
      }

      @include mq(ipadL) {
        margin-bottom: -7%;
      }
    }

    &__container {
      position: relative;
    }

    &__image-cont {
      position: relative;
      padding: 20px 20px;

      border-radius: 15px;
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
    }

    &__image {
      position: relative;
      width: 100%;

      border-radius: 10px;
      background-color: $color-bg;

      @include aspect-ratio(6, 7);
    }

    &__content {
      margin-top: 15px;

      q {
        @extend %font-quote;
      }

      p {
        margin-top: 25px;
        font-size: rem(14px);
        font-size: calc(14px * var(--rw));

        b {
          font-family: 'Tropiline';
          font-size: rem(13px);
          font-size: calc(13px * var(--rw));
          font-weight: 800;
        }
      }
    }

    &:nth-child(odd) .card__container .card__image-cont {
      background: linear-gradient(180deg, #c8c3b9 0%, #e5dfd8 100%);
    }
    &:nth-child(even) .card__container .card__image-cont {
      background: linear-gradient(180deg, #d2c7bb 0%, #ddd1c5 100%);
    }
  }
}
</style>
