<template>
  <div
    class="c-section-title"
    :data-animation="isAnimated ? 'sectionTitle' : isAnimated"
    :data-id="isAnimated ? 'scrollAnimations' : isAnimated"
  >
    <span class="section-title__line" />

    <h3 class="section-title__content">
      {{ content }}
    </h3>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',

  props: {
    content: {
      type: String,
      required: true,
    },
    isAnimated: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.c-section-title {
  display: flex;
  align-items: center;
  justify-content: center;

  @extend %font-section-title;

  span {
    display: inline-block;
    position: relative;
    width: 9vw;
    height: 1px;
    min-width: 70px;
    margin-right: 2.7vw;

    background-color: $black;
  }
}
</style>
