<template>
  <div
    :class="`c-logo-grid logo-grid--${theme}`"
    data-animation="logoGrid"
    data-id="scrollAnimations"
  >
    <div class="logo-grid__title">
      <p>{{ content.title }}</p>
      <em>{{ content.subtitle }}</em>
    </div>

    <div class="logo-grid__container" v-if="theme === 'thin'">
      <ul class="logo-grid__list">
        <li class="logo-grid__item" v-for="(img, i) in content.logos" :key="i">
          <img :src="img" alt="logo">
        </li>
      </ul>
    </div>

    <div class="logo-grid__container" v-else>
      <ul class="logo-grid__list" v-for="(column, i) in content.logos" :key="`column-${i}`">
        <li class="logo-grid__item" v-for="(img, index) in column" :key="index">
          <img :src="img" alt="logo">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoGrid',

  props: {
    theme: {
      type: String,
      required: false,
      default: 'thin',
    },
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.c-logo-grid {
  @include fluid-type(1440px, 3840px, 25px, 67px, 26px, 50px);

  p {
    line-height: 1;
  }

  em {
    font-family: 'Tropiline';
    font-style: normal;
    line-height: 1;
  }

  .logo-grid__container {
    margin-top: 2.9vh;
  }

  .logo-grid__list {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-flow: row wrap;

    li {
      position: relative;
      margin-top: 1.6vw;
      flex: 0 0 calc(50% - 0.8vw);

      background-color: rgba($black, 0.025);
      border-radius: 10px;

      @include aspect-ratio(4, 2.8);

      img {
        position: absolute;
        max-width: 80%;
        max-height: 60%;
        top: 50%;
        left: 50%;

        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  @include mq(ipadP) {
    &.logo-grid--wide {
      .logo-grid__container {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .logo-grid__list {
          flex: 0 0 calc(50% - 2.1vw);
        }
      }
    }
  }
}
</style>
